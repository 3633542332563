<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "cameraRecords.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <span class="headline"></span>
    </v-toolbar>
    

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <jqx-scheduler ref="myScheduler"
              :width="timelineWidth" :height="timelineHeight" :date="defaultJqxDate" :view="'timelineMonthView'" :resources="resources" :showLegend="true"
              :editDialog=false :enableHover=true :toolBarRangeFormat="'yyyy/MM/dd'"
              :appointmentDataFields="appointmentDataFields" :views="views"
              @appointmentDoubleClick="mySchedulerOnAppointmentClick($event)"
              @appointmentClick="singleClick($event)"
              @dateChange="mySchedulerOnDateChange($event)"
              :key="componentKey"
          />        
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'
import storage from '@/api/storage'
import JqxScheduler from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxscheduler.vue'


export default {
  components: {
      JqxScheduler
  },
  data: () => ({
    componentKey: 0,
    windowWidth: window.innerWidth,
    timelineWidth: '100%',
    // eslint-disable-next-line
    defaultJqxDate: new jqx.date(new Date("2024-10-20")),
    appointmentDataFields: {
        from: 'start',
        to: 'end',
        id: 'id',
        description: 'description',
        location: 'location',
        subject: 'subject',
        resourceId: 'calendar',
        draggable: 'draggable',
        resizable: 'resizable',
        background: 'background',
    },
    // views: [        
    //   {
    //     type: 'timelineMonthView',
    //     appointmentsRenderMode: 'exactTime'
    //   }
    // ],
    jqxDataSource: {
      dataType: 'array',
      dataFields: [
          { name: 'id', type: 'int' },
          { name: 'description', type: 'string' },
          { name: 'location', type: 'string' },
          { name: 'subject', type: 'string' },
          { name: 'calendar', type: 'string' },
          { name: 'start', type: 'date' },
          { name: 'end', type: 'date' },
          { name: 'draggable', type: 'boolean' },
          { name: 'resizable', type: 'boolean' },
          { name: 'background', type: 'string' }
      ],
      id: 'id',
      localData: [],
    }
  }),
  computed: {
    views() {
      return [        
        {
          type: 'timelineMonthView',
          appointmentsRenderMode: 'exactTime',
          timeSlotWidth: Math.floor(this.windowWidth / 41)
        }
      ]
    },
    timelineHeight() {
      return 100 * this.listings.length
    },
    resources () {
      return {
            colorScheme: 'scheme04',
            dataField: 'calendar',
            orientation: 'vertical',
            // eslint-disable-next-line
            // source: this.jqxDataAdapter
            source: new jqx.dataAdapter(this.jqxDataSource)
        }
    },
    ...mapState({
      reservations: state => state.host.reservations,
      reservationsTimestamp: state => state.host.reservationsTimestamp,
      listings: state => state.host.listings
    }),
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted: async function () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    let oneMonthEarlier = new Date(this.defaultJqxDate.toDate());
    oneMonthEarlier.setMonth(this.defaultJqxDate.toDate().getMonth() - 1)
    
    let oneMonthLater = new Date(this.defaultJqxDate.toDate());
    oneMonthLater.setMonth(this.defaultJqxDate.toDate().getMonth() + 1)

    console.log(oneMonthEarlier.toISOString())
    console.log(oneMonthLater.toISOString())

    await this.loadCalendarReservations({startDate: oneMonthEarlier.toISOString().split('T')[0], endDate: oneMonthLater.toISOString().split('T')[0]})
    
    // this.setJqxDatasource('mounted')
  },
  async beforeRouteEnter (to, from, next) {
    store.dispatch('host/pickListing')

    AppSyncSubscriber.disconnect('onUpdateReservation')

    AppSyncSubscriber.disconnect('onRemoveReservation')

    AppSyncSubscriber.connect('host', 'onUpdateReservation', null, 'refreshReservations')

    AppSyncSubscriber.connect('host', 'onRemoveReservation', null, 'postRemoveReservations')

    const error = await store.dispatch('host/loadListings').catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }

  },
  methods: {
    setJqxDatasource(parent) {
      console.log('setJqxDatasource', parent)
      this.jqxDataSource.localData = this.getCalendarRecords()
      this.$refs.myScheduler.source = new jqx.dataAdapter(this.jqxDataSource)
      this.$refs.myScheduler.showLegend = true
    },
    onResize() {
      this.windowWidth = window.innerWidth
      this.componentKey += 1

      setTimeout(() => {
        this.setJqxDatasource('onResize')
      }, 1)
      
    },
    getCalendarRecords: function () {
      const records = this.reservations.map((reservationItem, index) => {
        return {
          id: index,
          location: reservationItem.internalName,
          subject: reservationItem.guestName,
          calendar: reservationItem.internalName,
          start: new Date(`${reservationItem.checkInDate}T00:00:00`),
          end: new Date(`${reservationItem.checkOutDate}`),
          draggable: false,
          resizable: false,
          background: (reservationItem.isKeyNotified && reservationItem.isIdVerified) ? "#4CAF50" : reservationItem.isIdVerified ? "#2196F3" : reservationItem.isKeyNotified ? "#2196F3" : null
        }
      })

      console.log('getCalendarRecords', records)
      
      return records
    },
    singleClick: function (event) {
      console.log(event.args.appointment)
    },
    mySchedulerOnAppointmentClick: function (event) {
      // this.gotoMembers(this.reservations[parseInt(event.args.appointment.originalData.id)])
      console.log("mySchedulerOnAppointmentClick", event.args.appointment.originalData)
      this.gotoMembers(this.reservations[event.args.appointment.originalData.id])
    },
    mySchedulerOnDateChange1: async function (event) {

    },
    mySchedulerOnDateChange: async function (event) {

      console.log('mySchedulerOnDateChange', event.args.date.toDate().toISOString())

      this.defaultJqxDate = event.args.date

      let oneMonthEarlier = new Date(event.args.from.toDate())
      oneMonthEarlier.setMonth(event.args.from.toDate().getMonth() - 1)
      
      let oneMonthLater = new Date(event.args.from.toDate())
      oneMonthLater.setMonth(event.args.from.toDate().getMonth() + 1)

      this.$refs.myScheduler.showLegend = true

      await this.loadCalendarReservations({startDate: oneMonthEarlier.toISOString().split('T')[0], endDate: oneMonthLater.toISOString().split('T')[0]})

      // this.setJqxDatasource('mySchedulerOnDateChange')
    },
    ...mapActions({
      loadListings: 'host/loadListings',
      loadCalendarReservations: 'host/loadCalendarReservations',
      gotoMembers: 'host/gotoMembers'
    })
  },
  watch: {
    reservationsTimestamp(newVal, oldVal) {
      this.setJqxDatasource('reservationsTimestamp')
    }
  }
}
</script>